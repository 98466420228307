<template>
  <div>
    <v-row justify="center">
      <v-col md="6">
        <intract-smart-list
          class="mt-4"
          ref="all-magazine-posts-smart-list"
          paginated
          :endpoint="magazinePostsEndpoint"
          :item-options="{ title: 'title' }"
          @updateAllItemsList="(list) => (magazinePosts = list)"
        >
          <template v-slot:list-item="{ item: post }">
            <v-card
              @click="
                $router.push({
                  name: 'ViewMagazinePost',
                  params: { postId: post.id },
                })
              "
              class="mb-3 post-card mx-3"
            >
              <v-img
                v-if="post.image_file"
                :src="post.image_file['document']"
                height="180px"
                class="mt-1"
              ></v-img>

              <v-card-title class="pt-2 pb-1"
                ><h5 class="primary--text">
                  {{ post.title }}
                </h5>
              </v-card-title>

              <v-divider class="mx-4"></v-divider>

              <v-card-subtitle class="pb-1 pt-2">
                {{
                  Helper.truncateText(post.description, 180)
                }}
              </v-card-subtitle>

              <v-card-text class="text-caption">
                {{ moment(post.created).format("Do MMM") }}
              </v-card-text>
            </v-card>
          </template>
        </intract-smart-list>
        <v-fab-transition v-if="currentUser.is_admin && !isLoading">
          <v-btn
            color="primary"
            @click="postCreateEditSheet.visible = true"
            dark
            bottom
            right
            fixed
            fab
            elevation="0"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-col>
    </v-row>

    <intract-create-edit-sheet
      persistent
      v-if="currentUser.is_admin"
      title="Create Magazine Post"
      description="Showcase the happening of your Institute!"
      :visible="postCreateEditSheet.visible"
      @close="postCreateEditSheet.visible = false"
      :fields="formFields"
      enable-media
      :endpoint="endpoints.magazineViewSet"
      :edit-id="postCreateEditSheet.editId"
      :data-object="postCreateEditSheet.obj"
      :create-success-message="postCreateEditSheet.createSuccessMessage"
      @objectCreated="magazineCreated"
      @updateObject="(obj) => (postCreateEditSheet.obj = obj)"
    />
  </div>
</template>

<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import EventBus from "@utils/event_bus";

export default {
  name: "AllMagazinePosts",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: { IntractSmartList, IntractCreateEditSheet },
  data() {
    return {
      magazinePosts: [],
      moment: moment,
      postCreateEditSheet: {
        visible: false,
        createSuccessMessage: "Post created successfully!",
        editId: null,
        obj: {
          uploader: null,
          institution: null,
        },
      },
    };
  },
  computed: {
    magazinePostsEndpoint() {
      var url = this.Helper.addUrlParams(this.endpoints.magazineViewSet, [
        "ordering=-created",
      ]);
      return url;
    },
    formFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        author: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Author Name (optional)",
          required: false,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        image_file: {
          fieldType: CreateEditFieldTypes.IMAGEUPLOAD,
          multiple: false,
          label: "Image (Optional)",
          helper: "JPG, PNG, GIF, etc",
        },
      };
    },
  },
  methods: {
    async magazineCreated(obj) {
      this.$refs["all-magazine-posts-smart-list"].addItem(obj, true);
      this.$router.push({
        name: "ViewMagazinePost",
        params: { postId: obj.id },
      });
    },
    async onRefresh() {
      this.$refs["all-magazine-posts-smart-list"].getItems();
    },
    setListeners() {
      EventBus.$on("magazine__post_deleted", (id) => {
        if (this.$refs["all-magazine-posts-smart-list"])
          this.$refs["all-magazine-posts-smart-list"].removeItem(id);
      });
      EventBus.$on("magazine__post_updated", (postObject) => {
        var listPost = this.magazinePosts.find((p) => p.id == postObject.id);
        if (listPost) {
          listPost.description = postObject.description;
          listPost.title = postObject.title;
          listPost.image_file = postObject.image_file;
        }
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "AllMagazinePosts");
    }
    next();
  },
  created() {
    this.postCreateEditSheet.obj.uploader = this.currentUser.id;
    this.postCreateEditSheet.obj.institution = this.currentInstitution.id;
    this.setListeners();
  },
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>